import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    initiallyOpen: Boolean
  }

  static targets = ['content']

  observer;

  connect() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes') {
          this.toggleBodyFixed()
        }
      })
    })

    this.observer.observe(this.element, { attributes: true })

    if (this.initiallyOpenValue) this.show()
  }

  setContent(content) {
    this.contentTarget.innerHTML = content
  }

  disconnect() {
    this.observer?.disconnect()
  }

  checkBackdrop = event => {
    if (event.target === this.element) this.close()
  }

  close = () => {
    this.element.close()
  }

  show = () => {
    this.element.showModal()
  }

  thereAreOtherOpenedModals = () => (
    Array.from(document.querySelectorAll('dialog[open]')).some(m => m !== this.element)
  )

  toggleBodyFixed = () => Promise.resolve().then(() => {
    if (this.element.hasAttribute('open')) {
      this.fixBody()
    } else {
      this.unfixBody()
    }
  })

  fixBody = () => {
    if (this.thereAreOtherOpenedModals()) return
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.left = '0'
    document.body.style.right = '0'
    document.body.style.position = 'fixed'
  }

  unfixBody = () => {
    if (this.thereAreOtherOpenedModals()) return
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.left = ''
    document.body.style.right = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
}
