import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    value: String, label: String, multiple: Boolean
  }

  static targets = ['preview', 'checkmark', 'checkbox']

  handleCheckboxChange(e) {
    if (this.disabled) {
      return
    }

    if (e.target.checked) {
      this.element.classList.add('bg-surface-selected')

      if (!this.multipleValue) {
        this.checkmarkTarget.classList.remove('hidden')
      }
    } else {
      this.element.classList.remove('bg-surface-selected')

      if (!this.multipleValue) {
        this.checkmarkTarget.classList.add('hidden')
      }
    }
  }

  hide() {
    this.element.classList.add('hidden')
  }

  get disabled() {
    return this.checkboxTarget.disabled
  }

  get selected() {
    return this.checkboxTarget.checked
  }

  get isVisible() {
    return !this.element.classList.contains('hidden')
  }

  show() {
    this.element.classList.remove('hidden')
  }

  focus() {
    this.element.focus()
  }

  select() {
    if (this.selected) {
      return
    }

    this.checkboxTarget.checked = true
    this.checkboxTarget.dispatchEvent(new Event('change'))
  }

  deselect() {
    if (!this.selected) {
      return
    }

    this.checkboxTarget.checked = false
    this.checkboxTarget.dispatchEvent(new Event('change'))
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.deselect()
    } else {
      this.select()
    }
  }
}
