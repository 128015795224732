import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('message', this.handleMessage.bind(this))
  }

  disconnect() {
    window.removeEventListener('message', this.handleMessage.bind(this))
  }

  handleMessage(event) {
    if (event.origin.startsWith('https://omni.uscreen.tv')) {
      const { data } = event

      if (data?.payload?.name === 'dashboard-link-click' && data?.payload?.type === 'click') {
        // transform subscribers_new into subscribers/new but keep /subscribers as /subscribers
        const values = data.payload.data.split('_')
        const id = values[0]
        const dashboardId = values[1]

        const currentUrl = new URL(window.location.href)
        const pathParts = currentUrl.pathname.split('/')

        // remove the dashboard_id part if present
        if (pathParts[pathParts.length - 1] !== id) {
          pathParts.pop()
        }

        if (dashboardId) {
          pathParts.push(dashboardId)
        }

        currentUrl.pathname = pathParts.join('/')
        window.location.href = currentUrl.toString()
      }
    }
  }
}
